import type { ProviderAccessMap } from '@vgtv/svp-access/lib/access_mappings';
import type { Provider } from '@vgtv/api-client';

import { pascalCaseToKebabCase } from '../../../utils/string';
import type { AppConfig } from '../../../config.js';

type PulseProvider = 'vg' | 'brandstudio' | AppConfig['pulse']['site'];

export type SDRN = `sdrn:${PulseProvider}:${string}`;
export type SessionSDRN = `sdrn:${PulseProvider}:session:${string}`;
export type FlowSDRN = `sdrn:${PulseProvider}:flow:${string}`;

/**
 * Builds a Schibsted Data Resource Name from given parameters.
 */
export function getSDRN(
  provider: PulseProvider,
  objectType: string,
  id: string | number
) {
  return `sdrn:${
    provider.toLowerCase() as PulseProvider
  }:${objectType.toLowerCase()}:${id}` as const;
}

export const parseSDRN = (sdrn: SDRN) => {
  const parts = sdrn.split(':');

  if (parts.length !== 4) {
    return undefined;
  }

  return {
    provider: parts[1] as PulseProvider,
    objectType: parts[2],
    id: parts[3],
  };
};

/**
 * Builds a Schibsted Data Resource Name from given parameters.
 */
export function getElementSDRN(
  pageSDRN: SDRN,
  elementType: string,
  elementId?: string | number
) {
  const elementSDRN = `${pageSDRN}:element:${pascalCaseToKebabCase(
    elementType
  )}` as const;

  if (elementId) {
    return `${elementSDRN}:${elementId}` as const;
  }

  return elementSDRN;
}

export enum Brand {
  VG = 'vg',
  AFTONBLADET = 'aftonbladet',
}

export interface BrandSubscriptionMap {
  [Brand.VG]: Exclude<ProviderAccessMap[Provider.VGTV], 'premium'>;
  [Brand.AFTONBLADET]: Exclude<ProviderAccessMap[Provider.AB], 'login'>;
}

export const getSubscriptionSDRN = <T extends Brand>(
  brand: T,
  subscription: BrandSubscriptionMap[T]
) => {
  return `sdrn:${brand}:subscription:${subscription}` as const;
};
