import type { Unleash } from './unleashClient.js';

// this should be used ONLY for the shared parts
let unleash: Unleash | undefined;

export const setUnleash = (client: Unleash) => {
  unleash = client;
};

export { unleash };
