import { Provider } from '@vgtv/api-client';

import type { Vendor } from '../../../types';
import { sessionStorageHelper } from '../../../helpers/storage/index.js';
import { config } from '../../../config.js';

const productType = 'ResponsiveWeb';

export const getPulseProvider = (vendor: Vendor) => {
  if (config.provider === Provider.AB) {
    return {
      product: 'aftonbladet',
      productType,
      'spt:engage': 'AB',
    };
  }

  const isPwa = sessionStorageHelper.getItem('isPwa');

  return {
    productTag:
      vendor === 'brandstudio' ? 'partnerstudio' : config.pulse.productTag,
    productType: isPwa === 'true' ? 'PWA' : productType,
  };
};
