import { config } from '../../../config.js';
import type { PulseEventObject, PulseEventOrigin } from '../pulse.js';

import { getSDRN } from './getSDRN.js';
import type { UtmParameters } from './utmParams.js';
import { getUtmParams } from './utmParams.js';

interface PageViewData {
  url: string;
  timestamp: number;
  object?: PulseEventObject;
  utmParams?: UtmParameters;
  xDomainId?: string;
}

let previousPageViewData: PageViewData | undefined;

let currentPageViewData: PageViewData | undefined;

function setCurrentPageViewData(data: PageViewData): void {
  previousPageViewData = currentPageViewData;
  currentPageViewData = data;
}

function getOrigin(pageViewData: PageViewData): PulseEventOrigin {
  const utmParams = { ...getUtmParams(), ...pageViewData.utmParams };

  const origin: PulseEventOrigin = {
    url: pageViewData.url,
    source: utmParams?.utm_source,
    channel: utmParams?.utm_medium ?? utmParams?.utm_channel,
    campaign: utmParams?.utm_campaign,
    terms: utmParams?.utm_term,
    content: utmParams?.utm_content,
  };

  const { object } = pageViewData;

  if (object) {
    if (object.type) {
      origin['@type'] = object.type;
    }

    if (object.id && object.type) {
      origin['@id'] = getSDRN(config.pulse.site, object.type, object.id);
    }

    if (object['@id']) {
      origin['@id'] = object['@id'];
    }

    if (object.category) {
      origin.category = object.category;
    }
  }

  return origin;
}

function initializePageViewData() {
  const { referrer } = document;

  currentPageViewData = {
    url: referrer,
    timestamp: new Date().getTime(),
    utmParams: getUtmParams(),
    xDomainId: crypto.randomUUID(),
  };

  if (referrer === 'https://www.vg.no') {
    currentPageViewData.object = {
      '@id': getSDRN('vg', 'Frontpage', 'front'),
      type: 'Frontpage',
    };
  }

  if (referrer === 'https://www.aftonbladet.se') {
    currentPageViewData.object = {
      '@id': getSDRN('aftonbladet', 'Frontpage', 'front'),
      type: 'Frontpage',
    };
  }
}

/**
 * Returns origin for a new pulse page view event. This will be first based on referrer,
 * and then on subsequent tracked page views.
 */
function getPageViewOrigin(): PulseEventOrigin {
  if (!currentPageViewData) {
    initializePageViewData();
  }

  return getOrigin(currentPageViewData!);
}

/**
 * Returns current page view event.
 */
function getCurrentPageViewData(): PageViewData {
  if (!currentPageViewData) {
    initializePageViewData();
  }

  return currentPageViewData!;
}

/**
 * Returns origin of the previous page. This is based on the previously tracked
 * page view, since video page view occurs before all player events.
 * This is useful when dealing with click or SVP Player events.
 */
function getPreviousPageViewOrigin(): PulseEventOrigin | undefined {
  return previousPageViewData ? getOrigin(previousPageViewData) : undefined;
}

function getDurationSincePageView(): number {
  return new Date().getTime() - getCurrentPageViewData().timestamp;
}

export {
  getCurrentPageViewData,
  getDurationSincePageView,
  getPageViewOrigin,
  getPreviousPageViewOrigin,
  setCurrentPageViewData,
};
