import {
  createJSONWebStorage,
  sessionStorageHelper,
} from '../../helpers/storage';

export interface RecoveryMetadata {
  assetId?: number;
  lastKnownPosition: number;
  isPlaying: boolean;
}

export const storage = createJSONWebStorage<RecoveryMetadata>(
  'error-recovery-metadata',
  sessionStorageHelper
);

export const playerErrorMetadata = {
  persist: (metadata: RecoveryMetadata) => {
    storage.setItem(metadata);
  },

  consume: () => {
    const metadata = storage.getItem();

    storage.removeItem();

    return metadata;
  },
};
