/**
 * Wrapper for localStorage that handles catching possible errors thrown by the storage
 * The function/IIFE isLocalStorageAvailable is run immediately on init and if it's false
 * we assume no local storage is available, and so subsequent calls to set/get will have no effect (return null)
 */
import type { StorageAdapter } from './constants';
import { isLocalStorageAvailable, isSessionStorageAvailable } from './utils';
import inMemoryStorage from './inMemoryStorage';

export function createStorage(adapter: StorageAdapter): StorageAdapter {
  return {
    setItem(key: string, item: unknown) {
      try {
        adapter.setItem(key, String(item));
      } catch {}
    },

    getItem(key: string): string | null {
      return adapter.getItem(key);
    },

    removeItem(key: string) {
      adapter.removeItem(key);
    },

    clear() {
      adapter.clear();
    },
  };
}

export const sessionStorageHelper = createStorage(
  isSessionStorageAvailable ? sessionStorage : inMemoryStorage
);

export const localStorageHelper = createStorage(
  isLocalStorageAvailable ? localStorage : inMemoryStorage
);

export const inMemoryStorageHelper = createStorage(inMemoryStorage);

export const createWebStorage = (key: string, storage = localStorageHelper) => {
  const getItem = () => {
    return storage.getItem(key);
  };

  const setItem = (value: string) => {
    storage.setItem(key, value);
  };

  const removeItem = () => {
    storage.removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};

export const createJSONWebStorage = <T>(
  key: string,
  storage = localStorageHelper
) => {
  const webStorage = createWebStorage(key, storage);

  const getItem = () => {
    try {
      const data = webStorage.getItem();

      if (data) {
        return JSON.parse(data) as T;
      }
    } catch {
      // noop
    }

    return undefined;
  };

  const setItem = (value: T) => {
    webStorage.setItem(JSON.stringify(value));
  };

  const removeItem = () => {
    webStorage.removeItem();
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};
